import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SR from "scrollreveal";
import { AppState } from "../../Redux/Models";
import { Flex } from "../../Components";
import style from "./style.module.scss";
import { Hero } from "../../Components";
import { GetAboutLandingSectionAction } from "../../Redux/Actions/ContentActions";
import { TeamImage, TeamImagev2, TeamImagev3 } from "../../Assets";
import { AboutSection } from "../../Components/AboutPage";
import { TeamBioSection } from "../../Components/AboutPage/TeamBioSection";
import { Wave } from "../../Components/Wave";
import { CertificationBanner } from "../../Components/CertificationBanner";
import { HeaderHighlight } from "../../Components";
import { SEO } from "../../Components/SEO/SEO";
import { HeaderPageSpacer } from "../../Components/Header/HeaderPageSpacer";

const Header = React.memo((props: { content: any }) => {
  return (
    <Flex column>
      <SEO
        title={"EH - About Us"}
        description={
          "Learn about what makes Eagle Harbor Advisors the best option for you"
        }
      />
      <HeaderHighlight />
      {props.content}
    </Flex>
  );
});

const TeamImageSection = React.memo(() => {
  return (
    <>
      <img
        className={style.hero_img}
        draggable="false"
        alt="hero"
        src={TeamImagev3}
      />
    </>
  );
});

export const About = React.memo((props: any) => {
  const dispatch = useDispatch();
  const data = useSelector((s: AppState) => s.content.aboutLandingSection.data);

  useEffect(() => {
    SR().reveal(".about_img", {
      opacity: 0.2,
      viewFactor: 0.3,
      scale: 0.9,
      delay: 0,
      reset: false,
      distance: "50px",
      origin: "right",
    });
  }, []);

  useEffect(() => {
    dispatch(GetAboutLandingSectionAction());
  }, [dispatch]);

  return (
    <Flex id="our-vision" column className={style.wrapper}>
      <HeaderPageSpacer />
      <Flex column style={{ overflow: "hidden", position: "relative" }}>
        <Flex
          row
          className={style.background}
          align="center"
          justify="flex-end"
          style={{ width: "100%" }}
        >
          <div className={style.wave} style={{ width: "100%" }}>
            <Wave width={"120%"} height={500} />
          </div>
        </Flex>
        <Flex className={style.foreground} column style={{ width: "100%" }}>
          <Hero
            hideAppointmentBtn
            header={
              data && (
                <Header
                  content={
                    <h2 style={{ padding: 0, margin: 0, fontSize: "28px" }}>
                      {data?.SectionTitle}
                    </h2>
                  }
                />
              )
            }
            grabber={data && data?.SectionContent}
            className={`about_img ${style.image_wrapper}`}
            children={<TeamImageSection />}
          />
          {/* only visible on mobile */}
          <Flex
            className={style.mobile_team_img}
            style={{ backgroundImage: `url(${TeamImagev3}` }}
          />
          <AboutSection />
        </Flex>
      </Flex>
      <TeamBioSection />
      <CertificationBanner />
    </Flex>
  );
});
